import request from '@/utils/axios';

const module = '/index';

const API = {
    apiLogin: { url: '/login/index', method: 'post' },  // 登录
    logout : {url : '/user/logout' , method : 'post'},
    apiNodeList: { url: '/access/index', },    // 获取节点列表
    uploadFile: { url: '/common/upload/uploadFile', method: 'post', module: true },
    apiFileUpload: { url: '/common/upload/uploadBase', method: 'post', module: true },    // 文件上传

    search: { url: '/search/index', method: 'get' },//搜索

    indexMonitor: { url: '/dataMonitor/indexRecommend', method: 'get' },
    indexWarningData: { url: '/dataWarning/statistics', method: 'get' },//首页预警数据

    getType: { url: '/index/type', method: 'get' }, //获得指定类型
    getCompany: { url: '/index/jigou', method: 'get' },//获取机构
    forumIndex: { url: '/forum/index', method: 'get' },

    forumCollect: { url: '/userForum/collect', method: 'post' },
    forumGood: { url: '/userForum/good', method: 'post' },
    forumCount: { url: '/forum/count', method: 'get' },
    forumSend: { url: '/userForum/send', method: 'post' },
    forumDetail: { url: '/forum/detail', method: 'get' },
    forumSay: { url: '/userForum/say', method: "post" },
    forumComment: { url: '/forum/comment', method: "get" },
    forumCommentChild: { url: '/forum/comment_child', method: 'get' },
    forumReportType: { url: '/forum/report_type', method: 'get' },
    forumReport: { url: '/userForum/report', method: 'post' },
    forumDelete: { url: '/userForum/deleteForum', method: 'post' },
    forumCommentDelete: { url: '/userForum/deleteComment', method: 'post' },

    
    userFollow: { url: '/user/follow', method: 'get' },
    userFollowMe: { url: '/user/follow_me', method: 'post' },
    userForum: { url: '/user/forum', method: 'get' },
    userComment: { url: '/user/comment', method: 'get' },
    userHzcount: { url: '/user/hzcount', method: 'get' },
    userGood: { url: '/user/good', method: 'get' },
    userFollowList: { url: '/user/follow_list', method: 'get' },
    userCollect: { url: '/user/collect', method: 'get' },
    
    commonOssSetting: { url: '/common/ossSetting/getSetting', method: 'get' , module : true},
    commonFileSave: { url: '/common/upload/fileSave', method: 'post', module: true },//插入
    
    indexTui: { url: '/index/tui', method: 'get' }, //获取热门资讯和推荐帖子
    indexInformation: { url: '/index/information', method: 'get' },
    getInformationInfo: { url: '/index/informationInfo', method: 'get' },
    indexBanner: { url: '/index/banner', method: 'get' },//轮播图
    userChatSave: { url: '/user/chatSave', method: 'post' },
    userChat: { url: '/user/chat', method: 'get' },
    
    sendBindCode : {url : '/passport/sendBindCode' , method : 'post'},
    bindUnioind : {url : '/passport/bindUnionid' , method : 'post'},
    getWxLoginQrcode : {url : '/passport/weixin' , method : 'get'},
    passportLogin: { url: '/passport/login', method: 'post' },
    passportSendCode: { url: '/passport/sendcode', method: 'post' },

    passportRegister: { url: '/passport/register', method: 'post' },

    warningCity: { url: '/dataMonitor/getMonitorCity', method: 'get' },//获取城市
    warningCitySearch: { url: '/dataMonitor/getRecommendStatistics', method: 'get' },//监测城市搜索

    dataTree: { url: '/dataTree/index', method: 'get' },
    getTreeData: { url: '/dataTree/getTreeData', method: 'get' },
    getRecord: { url: '/dataTree/getRecord', method: 'get' },
    addRecord: { url: '/dataTree/addRecord', method: 'get' },
    dataCompute: { url: '/dataTree/compute', method: 'post' },
    moreCompute: { url: '/dataTree/moreCompute', method: 'post' },
    delRecord: { url: '/dataTree/delRecord', method: 'post' },//删除记录
    monitorGroup: { url: '/DataMonitor/getGroup', method: 'get' }, //获取监测分组列表
    addMonitorGroup: { url: '/DataMonitor/addGroup', method: 'post' }, //增加监测分组
    delMonitorGroup: { url: '/dataMonitor/delGroup', method: 'post' }, // 删除监测分组
    addMonitor: { url: '/dataMonitor/addMonitor', method: 'post' }, // 添加自选监测 (给当前数据绑定分组)
    delMonitor: { url: '/dataMonitor/delMonitor', method: 'post' }, //删除自选监测
    getMyMonitor: { url: '/dataMonitor/getMyMonitorList', method: "get" },//我的指标库
    getZfTop : {url : '/dataMonitor/getZfTop' , method : 'get'}, //涨幅跌幅榜 

    
    addData: { url: '/dataMonitor/addData', method: 'post' }, //添加自定义指标
    saveDateVal: { url: '/dataMonitor/saveDateVal', method: 'post' },//设置每天的值

    getWarningCity: { url: '/dataWarning/getWarningCity', method: 'get' },//预警中心城市
    warningNum: { url: '/dataWarning/warningNum', method: 'get' }, //获取预警数据
    warningChartAndArticle: { url: '/dataWarning/getWarningList', method: 'get' },//获取预警图表文章
    setWarningVal: { url: '/dataWarning/setVal', method: 'post' },//设置预警值
    getWarningInfo: { url: '/dataWarning/getWarningInfo', method: 'get' },//预警指标详情
    getLastWarningData : {url : '/dataWarning/getLastWarningData' , method : 'post'},

    userAuth: { url: '/user/auth', method: 'post' },//个人认证信息填写
    userAuth2: { url: '/user/auth2', method: 'post' },//企业认证信息填写
    userIndex: { url: '/user/index', meghto: 'get' },//用户信息

    indexVip: { url: '/index/vip', method: 'get' },//充值选项
    
    indexCompany: { url: '/index/company', method: 'get' },
    indexReport: { url: '/index/report', method: 'get' },//报告
    indexReportDetail: { url: '/index/reportDetail', method: "get" },//详情
    
    userSubscribe: { url: '/user/subscribe', method: 'post' },//订阅
    userSubscribeList: { url: '/user/subscribeList', method: 'get' },//已订阅列表
    mySubscribleArticle: {url : '/user/mySubscribeList' , method : 'get'},//以订阅的文章

    treeDetail: { url: '/dataTree/treeDetail', method: 'get' },
    userMessage: { url: '/user/msgSystem', method: 'get' },
    
    msgRead: { url: '/user/msgRead', method: 'post' },
    msgDelete: { url: '/user/msgDel', method: 'post' },
    readMsgAll: { url: '/user/readMsgAll', method: 'post' },
    delMsgAll: { url: '/user/delMsgAll', method: 'post' },
    
    sendLoginCode: { url: '/passport/sendMobileCode', method: 'post' },//发送登录验证码
    loginByCode: { url: '/passport/loginCode', method: 'post' },//验证码登录
    sendFindCode: { url: '/passport/sendFindCode', method: 'post' },
    resetPwd: { url: '/passport/resetPwd', method: 'post' },
    
    msgDetail: { url: '/user/msgDetail', method: 'get' },
    
    mobileChangeCode: {url:'/user/mobileChangeCode',method:'post'},//换绑手机号请求前发送验证码接口 步骤一
    mobileChangeNewCode: {url:'/user/mobileChangeNewCode',method:'post'},//请求发送换绑新手机号验证码接口 步骤二
    changePhone: {url:'/user/changePhone',method:'post'},//用户换绑手机号接口 步骤三
    getXieyiData: {url:'/index/getSetting',method:'get'},//获取登录用户协议

    getMapData: { url: '/other_api/map', method: "get" }, // 获取地图数据

    passportWeixinLogin: {url:'/passport/wxLoginInfo',method:'get'},

    getCompanyData : {url : '/pig_company/index/' , method : 'post'},

    indexRecommendTreeV2 : {url : '/index/getRecommendDataTree' , method : 'get'},

    vipRecord : {url : '/user/vipRecord' , method : 'get'},
    userHome : {url : '/forum/getUserInfo' , method : 'get'},

}

for (let key in API) {
    if (!API[key].module) {
        API[key].url = module + API[key].url;
    }
}

export {
    API
};