import { verifyLogin } from '@/utils'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=> import('@/views/home/index.vue'), // 主页
    redirect: '/index',
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index/index_v2.vue'), // 首页
        meta: {
          keepAlive : true,
          headerTransparent: true,
          title : '首页',
          menu : '首页',
        }
      },
      {
        path: 'jiaoliu/index',
        name: 'JiaoLiu',
        component: () => import('@/views/jiaoliu/index_v2.vue'), // 交流管理
        meta: {
          keepAlive: true,
          headerTransparent: true,
          title : '交流管理',
          menu : '观点交流',
        }
      },
      {
        path: 'my/point/:id',
        component: () => import("@/views/my/point.vue"), // 我的关注详情,
        meta: {
          keepAlive : true,
          title: "用户主页",
        }
      },
      {
        path: 'kuaixun/index',
        name: 'KuaiXun',
        component: () => import("@/views/kuaixun/index.vue"),
        meta: {
          keepAlive: true,
          menu : '诸猪快讯',
        }
      },
      {
        path: 'kuaixun/detail/:id',
        name: 'KuaiXunDetail',
        component: () => import("@/views/kuaixun/detail.vue"),
        meta : {
          menu : '诸猪快讯'
        }
      },
      {
        path: 'jiaoliu/detail/:id?',
        name: 'JiaoLiuDetail',
        component: ()=> import('@/views/jiaoliu/detail.vue'), // 交流管理 详情
        meta : {
          title : '交流详情',
          menu : '观点交流'
        }
      },
      {
        path: 'jiaoliu/publish/:type/:tab',
        name: 'JiaoLiuPublish',
        component: () => import('@/views/jiaoliu/publish.vue'),
        meta: {
          title: '发表文章',
          login: true,
          menu : '观点交流'
        }
      },
      {
        path: 'jiaoliu/article',
        name: 'JiaoLiuArticle',
        component: ()=> import('@/views/jiaoliu/article.vue'), // 交流管理 发表文章
        meta : {
          login : true,
          title : '发表文章',
          menu : '观点交流'
        }
      },
      {
        path: 'my/index/:id?',
        name: 'myIndex',
        component: ()=> import('@/views/my/index.vue'), // 个人主页 
        meta : {
          login : true,
          title : '个人主页'
        }
      },
      {
        path: 'my/center/:tab?',
        name: 'myCenter',
        component: () => import('@/views/my/center_v2.vue'), // 个人中心
        meta : {
          keepAlive : true,
          login : true,
          title : '个人中心'
        }
      },
      {
        path: 'jiance/index/:tab?',
        name: 'jianceIndex',
        component: () => import('@/views/jiance/index_v2.vue'), // 监测中心
        meta: {
          keepAlive: true,
          headerTransparent: true,
          title : '监测中心',
          menu : '监测中心'
        }
      },
      {
        path: 'data/index',
        name: 'dataIndex',
        component: ()=> import('@/views/data/index.vue'), // 数据中心,
        meta : {
          // keepAlive : true,
          login : true,
          title : '数据中心',
          menu : '数据中心'
        }
      },
      {
        path: 'yujing/index',
        name: 'yujingIndex',
        component: () => import('@/views/yujing/index_v2.vue'), // 预警中心
        meta : {
          keepAlive : true,
          title : '预警中心',
          menu : '预警中心'
        }
      },
      {
        path: 'message/index',
        name: 'messageIndex',
        component: ()=> import('@/views/message/index.vue'), // 消息中心 
        meta : {
          // keepAlive : true,
          title : '消息中心'
        }
      },
      {
        path: 'message/detail',
        name: 'messageDetail',
        component: ()=> import('@/views/message/detail.vue'), // 消息中心 
        meta : {
          title : '消息详情'
        }
      },
      {
        path: 'vip/people',
        name: 'vipPeople',
        component: ()=> import('@/views/vip/people/people.vue'), // 个人vip 
        meta : {
          login : true,
          title : '个人VIP'
        }
      },
      {
        path: 'vip/company',
        name: 'vipCompany',
        component: ()=> import('@/views/vip/company/company.vue'), // 企业vip 
        meta : {
          login : true,
          title : '企业VIP'
        }
      },
      {
        path: 'vip/company_center',
        name: 'vipCompanyCenter',
        component: ()=> import('@/views/vip/company/center.vue'), // 企业vip 个人中心 
        meta : {
          login : true,
          title : '个人中心'
        }
      },
      {
        path: 'zixun/index/:id?',
        name: 'zixunIndex',
        component: ()=> import('@/views/zixun/index.vue'), // 行业资讯
        meta: {
          keepAlive : true,
          title : '行业资讯',
          menu : '诸猪快讯'
        }
      },
      {
        path: 'zixun/detail/:id?',
        name: 'zixunDetail',
        component: ()=> import('@/views/zixun/detail.vue'), // 行业资讯
        meta : {
          title : '资讯详情',
          menu : '诸猪快讯'
        }
      },
      {
        path: 'zixun/use',
        name: 'zixunUse',
        component: ()=> import('@/views/zixun/use.vue'), // 使用教程
        meta : {
          title : '使用教程'
        }
      },
      {
        path: 'yanjiu/index',
        name: 'yanjiuIndex',
        component: ()=> import('@/views/yanjiu/index.vue'), // 研究报告
        meta : {
          title : '研究报告',
          menu : '诸猪快讯'
        }
      },
      {
        path: 'yanjiu/detail/:id?',
        name: 'yanjiuDetail',
        component: ()=> import('@/views/yanjiu/detail.vue'), // 研究报告  详情
        meta : {
          title : '研究报告详情',
          login: true,
          menu : '诸猪快讯'
        }
      },
      {
        path: 'my/info',
        name: 'myInfo',
        component: ()=> import('@/views/my/info.vue'), // 我的个人信息
        meta : {
          login : true,
          title : '我的个人信息'
        }
      },
      {
        path: 'my/verify',
        name: 'myVerify',
        component: ()=> import('@/views/my/verify.vue'), // 实名认证
        meta : {
          login : true,
          title : '实名认证'
        }
      },
      {
        path: 'search/index',
        name: 'searchIndex',
        component: () => import('@/views/search/index_v2.vue'), // 搜索结果页
        meta : {
          title : '搜索'
        }
      },
      {
        path: 'jiance/list',
        name: 'jianceList',
        component: ()=> import('@/views/jiance/list.vue'), // 监测中心列表页
        meta : {
          // keepAlive : true,
          title : '监测中心',
          menu : '监测中心'
        }
      },
      {
        path: 'jiance/zixuan',
        name: 'jianceZixuan',
        component: ()=> import('@/views/jiance/zixuan.vue'), // 监测中心- 自选监测
        meta : {
          // login : true,
          title : '自选监测',
          menu : '监测中心'
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/login/index.vue'), // 登录
    meta : {
      title : '登录'
    }
  },
  {
    path: '/echartScreen/company',
    name: 'echartIndex',
    component: ()=> import('@/views/echartScreen/company.vue'),
  },
  {
    path: '/echartScreen/chan/:type',
    name: 'echartChan',
    component: ()=> import('@/views/echartScreen/chan.vue'),
  },
  {
    path: '*',
    name: 'page404',
    component: ()=> import('@/views/pageError/page404.vue') // 404页面
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: ()=> ({ y: 0 }),
})


router.beforeEach((to, from, next)=>{
  var meta = to.meta || {};
  
  if(meta.login && !verifyLogin()) {
    next("/login")
  }else{
    next();
  }
  if(to.meta.title){
    document.title = to.meta.title;
  }
})

// 避免重复点击同一个路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
