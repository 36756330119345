import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './utils/axios';
import { API } from './api/apiUrl';

import 'reset-css';
import "swiper/css/swiper.css";
import './utils/element';

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

// rem
import './utils/rem';

import noData from './components/common/my-nodata.vue';
import Dialog_Overlay from './utils/dialog_overlay';

Vue.component('no-data', noData);
Vue.use(Dialog_Overlay)

Vue.prototype.$request = request;
Vue.prototype.$API = API;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');