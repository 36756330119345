import axios from 'axios';
import { Message, Loading } from 'element-ui';
import { dkLocalStorage, goLogin } from '.';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.timeout = 1000 * 20;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 全局加载进度条
// NProgress.configure({
//     easing: 'ease',
//     speed: 500,
//     showSpinner: false,
//     trickleSpeed: 200,
//     minimum: 0.1
// })

let loadingInstance = null;

axios.interceptors.request.use(config=>{
    if(config.headers?.loading) {
        loadingInstance = Loading.service({
            text: '正在拼命加载中...'
        });
    }else{ loadingInstance = null };

    config.headers['Authorization'] = dkLocalStorage('pctoken')
    return config;
}, err=> Promise.reject(err))

axios.interceptors.response.use(response=>{
    loadingInstance && loadingInstance.close();
    return response;
}, error=>{
    loadingInstance && loadingInstance.close();
    return Promise.reject(error);
})

const request = function(api , data = {} , loading = true ,  method = 'get') {
    return new Promise((resolve, reject)=>{
        var url = api;
        if(typeof api == 'object'){
            url = api.url;
            method = api.method||'get'
        }
        var params = {};
        for (var i in data) {
			if (typeof data[i] == 'object') {
				params[i] = JSON.stringify(data[i]);
			}else{
                params[i] = data[i];
            }
		}
        axios.request({
            url: url,
            method,
            data: method === 'post' ? params : '',
            params: method === 'get' ? params : '',
            headers: { loading }
        }).then(res=>{
            if(res.status === 200) {
                if(res.data.code === 0) {
                    return resolve(res.data);
                }else{
                    if(res.data.message){
                        Message.warning(res.data.message);
                    }
                    if (res.data.code == 10001) {
                        dkLocalStorage('userdata' , null);
                        setTimeout(() => {
                            goLogin();
                        }, 500);
                    }
                    resolve(res.data)
                }
            }else{
                Message.warning('网络错误,请重试！9999');
                // return reject(res);
            }
        }).catch(err=>{
            Message.warning('网络错误,请重试！');
            // return reject(err)
        })
    })
}

export default request;