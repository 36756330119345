<template>
    <div class="noData">
        <div class="icon"><img src="@/static/images/icon-nodata.png" alt=""></div>
        <span>{{ text }}</span>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: '暂无数据'
            }
        },
    }
</script>

<style scoped lang="scss">
    .noData {
        .icon {
            width: 1.26rem;
            margin: 0 auto .12rem;
        }
        font-size: .12rem;
        color: #8a96a3;
        text-align: center;
    }
</style>