export default {
    state: {
        menus: [],
        topTagsObj: {
            index: 0,
            list: []
        }
    },
    getters: {
        getVuexMenus(state) {
            return state.menus;
        },
        getVuexTags(state) {
            return state.topTagsObj
        }
    },
    mutations: {
        // 设置菜单
        setVuexMenus(state, arr) {
            state.menus = arr;
        },
        // 设置标签页
        setVuexTags(state, arr) {
            state.topTagsObj.list = arr;
        },
        // 设置标签页选中下标
        setVuexTagsIndex(state, path) {
            state.topTagsObj.index = path;
        }
    },
}