export const DataStep = ['天','周','月','季','半年','年','半月'];

export const DataWeeks = ['星期一','星期二','星期三','星期四','星期五','星期六','星期日',]

export const DataJiDu = ['一月，四月，七月，十月', '二月，五月，八月，十一月','三月，六月，九月，十二月'];

export const DataHalfYear = ['一月，七月', '二月，八月', '三月，九月', '四月，十月', '五月，十一月', '六月，十二月']

export const DataYear = ['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月',]

// 降低频率方式
export const DataPin = {
    0: [
        { label: '分拆', value: 1 },
        { label: '复制', value: 2 },
    ], // 日 周
    1: [
        { label: '合计值', value: 1 },
        { label: '平均值', value: 2 },
        { label: '第一条', value: 3 },
        { label: '最后一条', value: 4 },
        { label: '第N条', value: 5 },
        { label: '最大值', value: 6 },
        { label: '最小值', value: 7 },
        { label: '中位数', value: 8 },
        { label: '标准差', value: 9 },
        { label: '方差', value: 10 },
    ], // 季 半年 年
}

// 计算方式
export const computeTypes = ['变频','求和','偏移']

// 求和方式
export const qiuheTypes = ['移动平均', '滚动求和', '累计值', '累计平均值'];

//偏移方式
export const skewingType = ['向前' , '向后'];

export const pathInfo = process.env.NODE_ENV == 'production' ? '/view/pc' : '';