import { Dialog } from 'element-ui';

const Dialog_OverLay = {
    extends: Dialog,
    data() {
        return {
            isMouseDown: false,
        }
    },
    mounted() {
        this.$el.onmousedown = (e)=>{
            this.isMouseDown = e.target.classList.contains('el-dialog__wrapper');
        }
    },
    methods: {
        handleWrapperClick() {
            if (!this.closeOnClickModal || !this.isMouseDown) return;
            this.handleClose();
        },
    }
}

export default {
    install(Vue) {
        Vue.component(Dialog.name, Dialog_OverLay);
    }
}
