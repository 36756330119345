;(function() {
    function resize() {
        const minWidth = 1600;
        let html = document.querySelector('html');
        let cWidth = document.body.clientWidth || document.documentElement.clientWidth || window.innerWidth;
        if(!cWidth) { return ;}
        if(cWidth >= 1920) { html.style.fontSize = '100px'; }
        else if(cWidth >= minWidth) {
            html.style.fontSize = 100 * ( cWidth / 1920 ) + 'px'; 
        }
        else{
            html.style.fontSize = 100 * ( minWidth / 1920 ) + 'px';
        }
    }
    resize();
    window.onresize = resize;
})();