import Vue from 'vue'
import Vuex from 'vuex'

import vuexPersistedState from 'vuex-persistedstate'; // vuex 持久化

Vue.use(Vuex)

import MenuModule from './modules/menu';
import User from './modules/user';
import refresh from './modules/refresh';

export default new Vuex.Store({
  namespaced: true,
  state: {
    num: 666
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    menu: MenuModule,
    user: User,
    refresh : refresh
  },
  plugins: [
    vuexPersistedState({
      key: 'asideMenus'
    })
  ]
})
