export default{
    state : {
        userInfo : {},
    },
    getters : {
        getUserInfo(state){
            return state.userInfo.id ? state.userInfo : null;
        },
    },
    mutations : {
        setUserInfo(state , data){
            state.userInfo = data;
        }
    }
}