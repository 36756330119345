const CryptoJS = require("crypto-js");
import {  Message } from 'element-ui';
import { pathInfo } from './BaseData';
/**
 * 加密工具
 */
export function setCrypto(data) {
    var crypto = CryptoJS.AES.encrypt(JSON.stringify({ ...data, time: new Date().getTime() }), CryptoJS.enc.Utf8.parse('fuqpsldkcm158741'), { mode: CryptoJS.mode.ECB });
    return CryptoJS.enc.Base64.stringify(crypto.ciphertext);
}

/**
 * @description 密码复杂度验证 正则 密码中必须包含字母（不区分大小写）、数字，至少8个字符，最多30个字符
 */

export function verifyPwdComplex(val) {
    let reg = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}')
    return reg.test(val)
}

/**
 * 日期格式转换 时间戳转字符串
 * @returns 日期
 */
export function timeFormat(timestamp, format = null) {
    var time = new Date(timestamp);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    let text = ''
    switch (format) {
        case 0:
            text = `${y}-${m}-${d}`
            break;
        default:
            text = `${y}-${m}-${d} ${h}-${mm}-${s}`
            break;
    }
    return text;
}

export const formatTime = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

const _tempData = {};
export const tempData = {
    set : (key , val) => {
        if(val === null){
            delete _tempData[key];
        }else{
            _tempData[key] = val;
        }
    },
    get : (key)=>{
        return _tempData[key];
    }
};



export const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}
var platformKey = 'rcpc_';
export const dkLocalStorage = (key, value) => {
    key = platformKey + key;
    var time = new Date();
    if(value === null){
        localStorage.removeItem(key);
    }
    else if (value) {
        return localStorage.setItem(key, JSON.stringify({expire_time_temp:time.getTime() + 24*60*60*3*1000, value:value}))
    } else { 
        var item = JSON.parse(localStorage.getItem(key))
        if (item && time.getTime() < item.expire_time_temp) {
            return item.value
        } else { 
            localStorage.removeItem(key)
            return null
        }
    }
}

export const loginOut = ()=> {
    localStorage.removeItem(platformKey + 'pctoken');
    localStorage.removeItem(platformKey + 'userdata');
}

export const verifyLogin = (type) => { 
    let token = dkLocalStorage('pctoken')
    if (type && !token) { 
        Message({ type: "warning", message: '请先登录' });
        setTimeout(() => { 
            goLogin();
        },1000)
    }
    return token
}

export const goLogin = ()=>{
    window.location.href = process.env.VUE_APP_MY_PATH_NAME + '/login';
}

export const computeDataList = (allDataList , step = 6)=>{
    if(allDataList.length == 0){
        return [];
    }
    var dayList = {};
    var startTime = 0;
    var endTime = Date.now();
    var num = 0;
    if(step == 0){
        num = 7;
    }else if(step == 1){
        num = 30;
    }else if(step == 2){
        num = 30 * 6;
    }else if(step == 3){
        num = 365;
    }else if(step == 4){
        num = 365 * 3;
    }else if(step == 5){
        num = 365 * 5;
    }else if(step == 7){
        num = 15;
    }
    if(num){
        endTime = new Date(allDataList[0].date).getTime();
        var dayTime = 24 * 3600000;
        startTime = endTime - dayTime * num;
    }
    for(var item of allDataList){
        var time = new Date(item.date).getTime();
        if(startTime <= time && time <= endTime){
            var dateStr = filterDate(item.date , step);
            item.originDate = item.date;
            item.date = dateStr;
            dayList[item.date] = [];
        }
    }
    for(var day in dayList){
        var val = 0;
        for(var item of allDataList){
            if(item.date == day){
                val = item.val;
                break;
            }
        }
        dayList[day].push(val);
    }
    var list = [];
    for(var day in dayList){
        list.unshift([day].concat(dayList[day]));
    }
    return list;
}

export const dataToChartData = (val, options = {} , zoomShow = true) => {
    var list = computeDataList(val.all_data , val.step);
    var dataSource = [
        ['product' , val.name]
    ];
    dataSource = dataSource.concat(list);
    return {
        origin : val,
        id : val.id,
        data_tree_id : val.data_tree_id,
        title : val.name,
        tb : val.tb,
        hb : val.hb,
        tb_val : val.tb_val,
        hb_val : val.hb_val,
        tip : val.brife,
        time : val.new_date,
        echartOptions : {
            zoomShow : zoomShow,
            itemTypes : [Number(val.chart_type||0)],
            dataSource : dataSource,
            ...options
        }
    };
}

// 验证手机号
export function verifyPhone(val) {
    return /^1[3456789]\d{9}$/.test(val)
}

export const filterDate = (dateStr , step = 1)=>{
    if(step == 1){
        return dateStr;
    }
    var date = new Date(dateStr);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var time = date.getTime();
    var monthDayNum = new Date(year , month , 0).getDate();
    if(step == 2){
        return formatTime(new Date(time + 6 * 24 * 3600 * 1000)).substring(0 , 10);
        var start = new Date(year+'-01-01').getTime();
        return year +'年'+Math.ceil((date.getTime() - start) / 7 / 24 / 3600 / 1000)+'周';
    }else if(step == 3){
        return formatTime(new Date(year+'-'+month+'-'+monthDayNum)).substring(0 , 10);
        return year + '年' + month+'月';
    }else if(step == 4){
        var j = {1 : '-03-31',2 : '-06-30', 3 : '-09-30' , 4 : '-12-31'};
        return year + j[Math.ceil(month / 3)];
        return year +'/'+Math.ceil(month / 3)+'季度';
    }else if(step == 5){
        return year + (month < 7 ? '-06-30' : '-12-31');
        return year + (month < 7 ? '上半年' : '下半年');
    }else if(step == 6){
        return year+'-12-31';
        return year;
    }else if(step == 7){
        var d = day < 15 ? 15 : (new Date(year , month , 0)).getDate();
        return [year , month , d].join('-');
    }
}

export const sendTime = (dateStr) => {
    var d = new Date(dateStr);
    if(d.getTime() >  Date.now() - 25 * 60 * 1000){
        return Math.floor((Date.now() - d.getTime()) / 1000 / 60)+'分钟前';
    }
    return dateStr.substring(0 , 16);
}

export const shareArticle = (id , path = '/jiaoliu/detail/')=>{
    const input = document.createElement('input')
    input.setAttribute('readonly', 'readonly')
    let url = window.location.origin +pathInfo+ path + id
    input.setAttribute('value', url)
    document.body.appendChild(input)
    input.select()
    if (document.execCommand('copy')) {
        document.execCommand('copy')
        Message({ type: "success", message: '复制成功' });
    }
    document.body.removeChild(input)
}

export const copyData = (source) => {
    if (source instanceof Array) {
      var clone = [];
    } else {
      var clone = {};
    }
    for (let i in source) {
      if (typeof source[i] == 'object' && source[i] != null) {
        clone[i] = copyData(source[i]);
      } else {
        clone[i] = source[i];
      }
    }
    return clone;
}