export default{
    state:{
        action : {}
    },
    getters : {
        actionIsRefresh:(state)=>(action)=>{
            return state.action[action] ? true : false;
        }
    },
    mutations : {
        setAction(state , action){
            state.action[action.key] = action.val;
        }
    }
}