import Vue from 'vue';
import { Button, Container, Header, Aside, Main, Menu, MenuItem, MenuItemGroup, Submenu,
    Form, FormItem, Input, Card, Dropdown, DropdownMenu, DropdownItem, Table, TableColumn, Pagination, Loading,
    Select, Option, Dialog, Radio, RadioGroup, MessageBox, Switch, Tree, Message, Upload, Tag, TabPane, Tabs, Image, Checkbox, ColorPicker , Cascader , DatePicker, 
    CheckboxGroup
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message


Vue.use(Button);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Submenu);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Card);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Select);
Vue.use(Option);
Vue.use(Dialog);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Switch);
Vue.use(Tree);
Vue.use(Upload);
Vue.use(Tag);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Image);
Vue.use(Checkbox);
Vue.use(ColorPicker);
Vue.use(Cascader);
Vue.use(DatePicker);
Vue.use(CheckboxGroup);