<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import url("@/assets/css/common.scss");
@import url("@/assets/css/element.scss");
@import url("@/assets/css/variable.scss");
.el-message_icon {
  width: 10px!important;
}
strong{
    font-weight: bold  !important;
}

#app > div > div:nth-child(2) {
  min-height: 80vh;
}
</style>
